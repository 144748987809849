<template>
  <div class="conversations-filters">
    <Popover
      offset-y="8px"
      no-padding
      @show="filtersOpen = true"
      @hide="filtersOpen = false"
      @interface="popoverInterface = $event"
    >
      <template #trigger>
        <ButtonV2
          type="secondary"
          :class="{ 'filters-toggle--active': filtersOpen }"
          class="filters-toggle"
          :title="$t('shared.conversations.filter_conversations')"
        >
          <Icon name="filters" />
          <span
            v-if="!filtersOpen && activeFiltersCount"
            class="filters-toggle__active-filters-badge"
            >{{ activeFiltersCount }}</span
          >
        </ButtonV2>
      </template>
      <template #content>
        <div style="padding: 24px">
          <h3 class="filters-modal__headline">
            {{ $t("shared.conversations.filters") }}
          </h3>

          <div v-if="featureOgDirectEnabled" class="select-wrapper">
            <h4 class="select-wrapper__label">
              {{ $t("shared.conversations.direct.label") }}
            </h4>
            <SelectionDropdown
              v-model="localModels.og_direct"
              :items="directOptions"
              :placeholder="$t('shared.conversations.direct.placeholder')"
              content-width="100%"
              content-max-height="300px"
              offset-y="8px"
              :max-selections="1"
            />
          </div>

          <div class="select-wrapper">
            <h4 class="select-wrapper__label">
              {{
                isPartnerApp
                  ? $t("shared.conversations.customer")
                  : $t("shared.conversations.partner")
              }}
            </h4>
            <SelectionDropdown
              :model-value="
                isPartnerApp ? localModels.selectedCustomers : localModels.selectedPartners
              "
              :items="contactsOptions"
              :placeholder="
                isPartnerApp
                  ? $t('shared.conversations.select_customers')
                  : $t('shared.conversations.select_partners')
              "
              content-width="100%"
              content-max-height="300px"
              offset-y="8px"
              :no-items-text="
                isPartnerApp
                  ? $t('shared.conversations.no_customers_found')
                  : $t('shared.conversations.no_partners_found')
              "
              @update:modelValue="
                isPartnerApp
                  ? (localModels.selectedCustomers = $event)
                  : (localModels.selectedPartners = $event)
              "
            />
          </div>

          <div class="select-wrapper">
            <h4 class="select-wrapper__label">Service</h4>
            <SelectionDropdown
              v-model="localModels.selectedServices"
              :items="servicesOptions"
              :placeholder="$t('shared.conversations.select_services')"
              content-width="100%"
              content-max-height="300px"
              offset-y="8px"
              :no-items-text="$t('shared.conversations.no_services_found')"
            />
          </div>

          <div class="select-wrapper">
            <h4 class="select-wrapper__label" :title="$t('shared.conversations.contact_person')">
              {{ $t("shared.conversations.contact_person") }}
            </h4>
            <SelectionDropdown
              v-model="localModels.selectedContactPersons"
              :items="contactPersonOptions"
              :placeholder="$t('shared.conversations.select_contact_persons')"
              content-width="100%"
              content-max-height="300px"
              offset-y="8px"
              :no-items-text="$t('shared.conversations.no_contact_persons')"
            />
          </div>

          <div v-if="isCustomerApp" class="select-wrapper">
            <h4 class="select-wrapper__label" style="white-space: normal">
              {{ $t("shared.verified_partner") }}
            </h4>
            <SelectionDropdown
              v-model="localModels.isVerified"
              :items="verifiedPartnerOptions"
              :placeholder="$t('shared.conversations.select_if_verified')"
              content-width="100%"
              content-max-height="300px"
              offset-y="8px"
              :max-selections="1"
            />
          </div>

          <div v-if="isPartnerApp && useHandling && featureHandlingEnabled" class="select-wrapper">
            <h4 class="select-wrapper__label" style="white-space: normal">
              {{ $t("shared.conversations.handling") }}
            </h4>
            <SelectionDropdown
              v-model="localModels.isHandled"
              :items="conversationHandledOptions"
              :placeholder="$t('shared.conversations.select_handling')"
              content-width="100%"
              content-max-height="300px"
              offset-y="8px"
              :max-selections="1"
            />
          </div>

          <div class="buttons">
            <ButtonV2 type="text" color="grey" class="apply-button" @click="clearAll">
              {{ $t("shared.dropdown.clear") }}
            </ButtonV2>
          </div>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import { APP_NAMES } from '@officeguru/webapp-shared/src/configuration/app-names';
import debounce from 'lodash/debounce';
import ButtonV2 from '../../../components/ButtonV2.vue';
import Popover from '../../../components/Popover.vue';
import SelectionDropdown from '../../../components/SelectionDropdown.vue';
import Icon from '../../../components/Icon.vue';

export default {
  name: 'ConversationsFilters',
  components: {
    Icon,
    ButtonV2,
    Popover,
    SelectionDropdown,
  },
  inject: ['api'],
  props: {
    filterModels: {
      type: Object,
      required: true,
    },
    useHandling: {
      type: Boolean,
    },
  },
  emits: ['apply', 'clear'],
  data() {
    return {
      filtersOpen: false,
      contacts: [],
      services: [],
      contactPersons: [],
      localModels: {
        selectedPartners: [],
        selectedCustomers: [],
        selectedServices: [],
        isVerified: [],
        selectedContactPersons: [],
        isHandled: [],
        og_direct: [],
      },
      popoverInterface: null,
    };
  },
  computed: {
    featureHandlingEnabled() {
      return this.$store.getters['split/canAccess']('vendor-handle-conversations');
    },
    featureOgDirectEnabled() {
      return this.$store.getters['split/canAccess']('og-direct');
    },
    activeFiltersCount() {
      const models = [
        this.filterModels.partners?.partners || [],
        this.filterModels.customers?.customers || [],
        this.filterModels.services?.services || [],
        this.filterModels.contactPersons?.contactPersons || [],
        typeof this.filterModels.isVerified?.isVerified === 'boolean' ? [1] : [],
        typeof this.filterModels.isHandled?.isHandled === 'boolean' ? [1] : [],
        typeof this.filterModels.og_direct?.og_direct === 'boolean' ? [1] : [],
      ];
      return models.reduce((acc, model) => acc + model.length, 0);
    },
    isPartnerApp() {
      return this.$appName === APP_NAMES.PARTNER;
    },
    isCustomerApp() {
      return this.$appName === APP_NAMES.CUSTOMER;
    },
    contactPersonOptions() {
      return this.contactPersons.map((contactPerson) => ({
        value: contactPerson.id,
        label: contactPerson.name,
      }));
    },
    directOptions() {
      return [
        {
          label: this.$t('shared.conversations.direct.direct_agreement'),
          value: true,
        },
        {
          label: this.$t('shared.conversations.direct.officeguru_contract'),
          value: false,
        },
      ];
    },
    contactsOptions() {
      if (!this.contacts) return [];
      return this.contacts
        .map(({ id, name }) => ({
          label: name,
          value: id,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    servicesOptions() {
      if (!this.services) return [];
      return this.services
        .map(({ name }) => ({
          label: name,
          value: name,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    verifiedPartnerOptions() {
      return [
        {
          label: this.$t('shared.verified'),
          value: true,
        },
        {
          label: this.$t('shared.not_verified'),
          value: false,
        },
      ];
    },
    conversationHandledOptions() {
      return [
        {
          label: this.$t('shared.conversations.handled'),
          value: true,
        },
        {
          label: this.$t('shared.conversations.not_handled'),
          value: false,
        },
      ];
    },
  },
  watch: {
    localModels: {
      handler() {
        this.applyDebounced();
      },
      deep: true,
    },
    filtersOpen(oldVal, newVal) {
      if (oldVal && !newVal) this.resetInternalStateToModelValues();
    },
  },
  created() {
    this.fetchFilterOptionValues();
  },
  methods: {
    async fetchFilterOptionValues() {
      const endpoints = {
        customer: {
          services: '/customer/services',
          contacts: '/customer/conversations/partners',
          contactPersons: '/customer/users',
        },
        partner: {
          services: '/partner/services',
          contacts: '/partner/conversations/customers',
          contactPersons: '/partner/users',
        },
      };

      if (!endpoints[this.$appName]) {
        throw new Error(`Unsupported app name: ${this.$appName}`);
      }

      const servicesResponse = await this.api.get(endpoints[this.$appName].services, {
        params: {
          per_page: 9999,
          with_external: true,
        },
      });
      const contactsResponse = await this.api.get(endpoints[this.$appName].contacts, {
        params: {
          per_page: 9999,
          // @TODO: We're not getting the external partners yet
        },
      });

      const contactPersonsResponse = await this.api.get(endpoints[this.$appName].contactPersons, {
        params: {
          per_page: 9999,
        },
      });
      this.contactPersons = contactPersonsResponse.data.data;

      this.services = servicesResponse.data.data.filter((d) => d.name);
      this.contacts = contactsResponse.data.data.filter((d) => d.id);
    },
    applyDebounced: debounce(function applyDebounced() {
      this.apply();
    }, 700),
    apply() {
      this.$emit('apply', {
        services: this.localModels.selectedServices,
        partners: this.localModels.selectedPartners,
        customers: this.localModels.selectedCustomers,
        contactPersons: this.localModels.selectedContactPersons,
        isVerified:
          typeof this.localModels.isVerified[0] === 'boolean'
            ? this.localModels.isVerified[0]
            : null,
        isHandled:
          typeof this.localModels.isHandled[0] === 'boolean' ? this.localModels.isHandled[0] : null,
        og_direct:
          typeof this.localModels.og_direct[0] === 'boolean' ? this.localModels.og_direct[0] : null,
      });
    },
    close() {
      this.popoverInterface?.hide?.();
    },
    // this completely clears the internal state and communicates it to the outside
    clearAll() {
      this.localModels.selectedPartners = [];
      this.localModels.selectedCustomers = [];
      this.localModels.selectedServices = [];
      this.localModels.selectedContactPersons = [];
      this.localModels.isVerified = [];
      this.localModels.isHandled = [];
      this.localModels.og_direct = [];

      this.$emit('clear');
      this.popoverInterface?.hide?.();
    },
    resetInternalStateToModelValues() {
      this.localModels.selectedPartners = [...this.filterModels.partners.partners];
      this.localModels.selectedCustomers = [...this.filterModels.customers.customers];
      this.localModels.selectedServices = [...this.filterModels.services.services];
      /* eslint-disable-next-line */
      this.localModels.selectedContactPersons = [
        ...this.filterModels.contactPersons.contactPersons,
      ];

      if (typeof this.filterModels.isVerified.isVerified === 'boolean') {
        this.localModels.isVerified = [this.filterModels.isVerified.isVerified];
      }
      if (typeof this.filterModels.isHandled.isHandled === 'boolean') {
        this.localModels.isHandled = [this.filterModels.isHandled.isHandled];
      }
      if (typeof this.filterModels.og_direct.og_direct === 'boolean') {
        this.localModels.og_direct = [this.filterModels.og_direct.og_direct];
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../style/main";

.conversations-filters {
  position: relative;
}

.filters-toggle {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px !important;
  width: 32px;
  padding: 0 !important;
  color: $color-ui-interactive-grey-dark;
  background: none;
  transition: background-color .2s linear, color .2s linear;

  :deep(span) {
    height: 16px;
  }

  &--active {
    color: white;
    background: $color-ui-interactive-grey-dark;

    &:hover {
      background: $color-ui-interactive-grey-dark !important;
    }
  }

  &__active-filters-badge {
    @include font-caption-semi-bold;
    padding: 0 3px;
    pointer-events: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -7px;
    top: -7px;
    border-radius: 16px;
    background: $color-grey-tinted-900;
    border: 2px solid white;
    height: 18px;
    min-width: 18px; // needs to grow to fit multi digit numbers
  }
}

.filters-modal {
  display: flex;
  flex-og_direction: column;
  position: absolute;
  min-width: 400px;
  min-height: 300px;
  border: 1px solid $color-grey-tinted-200;
  left: 0;
  top: 40px;

  &__headline {
    @include font-body-16-semi-bold;
    color: $color-grey-tinted-900;
    margin-bottom: 24px;
  }
}

.select-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &__label {
    @include font-body-14-normal;

    @include overflow-ellipsis;
    color: $color-grey-tinted-900;
    display: block;
    width: 120px;
    margin-right: 16px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.apply-button {
  align-self: flex-end;
}
</style>
